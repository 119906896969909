import './error-maintenance.scss';
import Header from '../../../organisms/header/header.js'
import {useTranslation} from "react-i18next";

function ErrorMaintenance() {

  const { t } = useTranslation();

  return (
    <>
      <div className="App">
        <Header/>
        <main>
          <section className="error">
            <div className="inner">
              <strong className="code"><span className="icon-maintenance"></span></strong>
              <h2>{t('error-maintenance-title')}</h2>
              <p>{t('error-maintenance-text')}</p>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default ErrorMaintenance;