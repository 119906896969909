import './hac-drawer.scss';
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../../utils/apiClient';
import { ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function HacDrawer() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [categories, setCategories] = useState([]);
  const [firstTimeOpen, setFirstTimeOpen] = useState(true);
  const token = sessionStorage.getItem('token') || null;

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
    if (isOpen && firstTimeOpen) {
      fetchCategories();
      setFirstTimeOpen(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/hac/preferences-categories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleExpand = (itemName) => {
    setExpandedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  const navigate = useNavigate();
  const logout = async () => {
    sessionStorage.clear();
    toggleDrawer(false)();
    navigate('/login/logout');
  };

  const DrawerList = (
    <ClickAwayListener onClickAway={toggleDrawer(false)}>
      <div className="hac-drawer">
        <Button className="close-drawer" onClick={toggleDrawer(false)}>
          <span className="icon-close"></span>
        </Button>
        <ul>
          <li>
            <Link to="/hac/main" onClick={toggleDrawer(false)}>
              {t('hac-menu-home')}
            </Link>
          </li>
          <li onClick={() => handleExpand('profile')}>
            <span>
              {t('hac-menu-profile-title')}{' '}
              {expandedItem === 'profile' ? (
                <span className="icon-chevron-up"></span>
              ) : (
                <span className="icon-chevron-down"></span>
              )}
            </span>
            <Collapse in={expandedItem === 'profile'}>
              <ul onClick={toggleDrawer(false)}>
                <li>
                  <Link to="/hac/profile/basic-info">
                    {t('hac-menu-profile-menu1')}
                  </Link>
                </li>
                <li>
                  <Link to="/hac/profile/vehicles">
                    {t('hac-menu-profile-menu2')}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li onClick={() => handleExpand('preferences')}>
            <span>
              {t('hac-menu-preferences-title')}{' '}
              {expandedItem === 'preferences' ? (
                <span className="icon-chevron-up"></span>
              ) : (
                <span className="icon-chevron-down"></span>
              )}
            </span>
            <Collapse in={expandedItem === 'preferences'}>
              <ul onClick={toggleDrawer(false)}>
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link
                      to={`/hac/preferences?cat=${category.id}`}
                      state={{ categories }}
                    >
                      {t(`hac-preferences-${category.id}`)}
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapse>
          </li>
          <li>
            <Link to="/hac/settings">{t('common-account-settings')}</Link>
          </li>
        </ul>
        <Divider />
        <ul>
          <li onClick={logout}>{t('common-sign-out')}</li>
        </ul>
      </div>
    </ClickAwayListener>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(!open)} className="drawer-trigger">
        <span className="icon-menu"></span>
      </Button>
      <Drawer open={open} className="hac-drawer">
        {DrawerList}
      </Drawer>
    </div>
  );
}
