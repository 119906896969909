import apiClient from '../../../../utils/apiClient';

export const handleFileUpload = async (
  file,
  setLoading,
  setMsg,
  setFileUploaded,
  setLoadMsg
) => {
  setLoading(true);

  const formData = new FormData();
  formData.append('file', file);

  try {
    await apiClient.post(
      `${process.env.REACT_APP_API_URL}/loader/upload-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    setMsg('File uploaded successfully');
    setFileUploaded(true);
  } catch (error) {
    setMsg('Error uploading file');
  } finally {
    setLoading(false);
  }
};

export const handleDownload = async () => {
  try {
    const response = await apiClient.get(
      `${process.env.REACT_APP_API_URL}/loader/download-file`,
      {
        responseType: 'blob',
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'DataHacProgramme.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    console.error('Error al descargar el fichero:', error);
  }
};

export const handleLoad = async (endpoint, setLoadMsg, setHasErrors) => {
  setLoadMsg('');

  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_API_URL}/loader${endpoint}`
    );
    if (response.status === 200) {
      const hasErrors = response.data.errors.length > 0;
      const errors = response.data.errors.join('\n');
      const message =
        response.data.message ||
        'OK Created ' + response.data.created + ' elements. \n\n ' + errors;

      setHasErrors(hasErrors);
      setLoadMsg(message);
    } else {
      setHasErrors(true);
      setLoadMsg(`Error: ${response.data.message || 'Unknown error occurred'}`);
    }
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'Error loading data';
    setHasErrors(true);
    setLoadMsg(`${errorMessage}`);
  }
};

export const handleReset = (
  setFileUploaded,
  setMsg,
  setFileInputKey,
  setFileUploadedAgain,
  setLoadMsg,
  setHasErrors
) => {
  setFileUploaded(false);
  setMsg('');
  setFileInputKey((prevKey) => prevKey + 1);
  setFileUploadedAgain(false);
  setLoadMsg('');
  setHasErrors(false);
};

export const handleOpenModal = (
  setModalAction,
  setModalText,
  setOpen,
  action,
  modalText
) => {
  setModalText(() => modalText);
  setModalAction(() => action);
  setOpen(true);
};

export const handleClose = (setOpen) => {
  setOpen(false);
};

export const handleDelete = async (email, setLoadMsg, callback) => {
  try {
    const response = await apiClient.delete(
      `${process.env.REACT_APP_API_URL}/hac/${email}`
    );
    if (response.status === 200) {
      setLoadMsg('User deleted successfully');
    } else {
      setLoadMsg(`Error: ${response.data.message || 'User not found'}`);
    }
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'Error deleting user';
    setLoadMsg(`${errorMessage}`);
    console.error('Error:', error);
  } finally {
    callback();
  }
};

export const handleExportToSv = async (hacId, setLoadMsg, callback) => {
  try {
    const response = await apiClient.post(
      `${process.env.REACT_APP_API_URL}/admin/resendSvData?hacId=${hacId}`
    );
    if (response.status === 200) {
      setLoadMsg('Data exported');
    } else {
      setLoadMsg(`Error: ${response.data.message || 'Hac not found'}`);
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || 'Error exporting data user';
    setLoadMsg(`${errorMessage}`);
  } finally {
    callback();
  }
};
