import './modal-confirmation.scss';
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ModalConfirmation({ open, handleClose, onSubmit, formRef }) {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({});
  useEffect(() => {
    setFormData(Object.fromEntries(new FormData(formRef.current).entries()));
  }, [formRef]);

  const { name, last_name, email } = formData;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="box-confirmation">
        <button
          className="close"
          onClick={() => {
            handleClose();
          }}
        >
          <span className="icon-close"></span>
        </button>
        <div className="box-content">
          <h2 id="modal-modal-title">{t('modal-are-you-sure')}</h2>
          <p id="modal-modal-description">{t('modal-send-invite') + ':'}</p>
          <p id="hac-name">
            {name} {last_name}
          </p>
          <p id="hac-email">{email} </p>
          <div className="modal-buttons">
            <Button
              variant="contained"
              className="btn button-mod cancel-mod"
              onClick={handleClose}
            >
              {t('common-cancel')}
            </Button>
            <Button
              variant="contained"
              className="btn button-mod confirm-mod"
              type="submit"
              onClick={onSubmit}
            >
              {t('modal-confirm-nomination')}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalConfirmation;
