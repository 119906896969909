import './password-recovery.scss';
import Header from '../../../organisms/header/header.js';
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function PasswordRecovery() {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [passwordRecoveryCompleted, setPasswordRecoveryCompleted] =
    useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get('email');

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password/${email}`
      );

      // Actualizar el estado para mostrar el mensaje de recuperación de contraseña completada
      setPasswordRecoveryCompleted(true);
    } catch (error) {
      console.error(
        'Error al enviar el correo electrónico de recuperación de contraseña:',
        error
      );
      // Manejar errores de solicitud
    }
  };

  return (
    <div className="App">
      <Header />
      <main>
        {!passwordRecoveryCompleted && (
          <section className="password-recovery">
            <div className="inner">
              <h2>{t('password-recovery-title1')}</h2>
              <p>{t('password-recovery-text1')}</p>
              <form onSubmit={handleFormSubmit}>
                <div className="form-control">
                  <label htmlFor="email">{t('common-email')}</label>
                  <input
                    type="email"
                    id="email"
                    maxLength="50"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button className="btn" type="submit">
                  {t('password-recovery-action1')}
                </button>
              </form>
            </div>
          </section>
        )}

        {passwordRecoveryCompleted && (
          <section className="password-recovery">
            <div className="inner">
              <h2>{t('password-recovery-title2')}</h2>
              <p>{t('password-recovery-text2a')}</p>
              <p>{t('password-recovery-text2b')}</p>
            </div>
          </section>
        )}
      </main>
    </div>
  );
}

export default PasswordRecovery;
