import './table-hac-nominated.scss';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import apiClient from '../../../../utils/apiClient';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Snackbar } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import decodeToken from "../../../../utils/decode-token";

function TableHacNominated({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [selectedRetailerId, setSelectedRetailerId] = useState('');
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const userId = decodeToken(token).id;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/hac/retailer-salesperson?status=nominated`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const sortedRows = response.data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.nomination_date.split('-').map(Number);
            const [dayB, monthB, yearB] = b.nomination_date.split('-').map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateB - dateA;
          });

          const storedRetailerId = sessionStorage.getItem('filter-retailer');
          if (storedRetailerId) {
            const filtered = sortedRows.filter(row => row.retailer_info.id === Number(storedRetailerId));
            setFilteredRows(filtered);
            setSelectedRetailerId(storedRetailerId);
          } else {
            setFilteredRows(sortedRows);
          }

          setRows(sortedRows);
          onDataLoaded();
        } else if (response.status === 204) {
          setRows([]);
          setFilteredRows([]);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      } finally {
        setDataLoaded(true);
      }

      const userApiUrl = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${userId}`;
      try {
        const userResponse = await apiClient.get(userApiUrl, config);
        setRetailerOptions(userResponse.data.retailerInfo || []);
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
      }
    };

    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded, t, userId]);

  const handleApplyFilter = () => {
    sessionStorage.setItem('filter-retailer', selectedRetailerId);
    if (selectedRetailerId) {
      const filtered = rows.filter(row => row.retailer_info.id === Number(selectedRetailerId));
      setFilteredRows(filtered);
    } else {
      setFilteredRows(rows);
    }
  };

  return (
    <>
      {dataLoaded ? (
        rows && rows.length !== 0 ? (
          <div>
            <div className="intro">
              <div className="text">
                <h4>{t('retailer-main-nominated-title')}</h4>
                <p>{t('retailer-main-nominated-text')}</p>
              </div>
              <div className="actions">
                <Link to="/retailer/nominate-hac" className="btn small">
                  <span className="icon-plus"></span>{' '}
                  {t('retailer-main-nominated-action')}
                </Link>
              </div>
            </div>
            {role === 'retailerGM' && (
              <div className="filters">
                <p>{t('common-search')}</p>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={6} md={4}>
                    <div className="form-control">
                      <label htmlFor="retailer">{t('common-retailer')}</label>
                      <select
                        name="retailer"
                        id="retailer"
                        className="normal"
                        value={selectedRetailerId}
                        onChange={(e) => setSelectedRetailerId(e.target.value)}
                      >
                        <option value="">{t('common-all-retailers')}</option>
                        {retailerOptions.map((retailer) => (
                          <option key={retailer.id} value={retailer.id}>
                            {retailer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={6} md={2}>
                    <div className="actions">
                      <button className="btn" onClick={handleApplyFilter}>
                        {t('common-apply')}
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            {filteredRows.length !== 0 ? (
              <div className="table-wrapper">
                <Table
                  aria-label="simple table"
                  className={`user-table retailer-nominated ${
                    role === 'retailerGM' ? 'retailerGM' : ''
                  }`}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"><span className="text">{t('common-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-last-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-phone')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-address')}</span></TableCell>
                      {role === 'retailerGM' && (
                        <TableCell align="left"><span className="text">{t('common-retailer')}</span></TableCell>
                        )}
                      <TableCell align="left"><span className="text">{t('common-nomination-date')}</span></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">
                          <span className="text">{row.name}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">{row.last_name}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {row.prefix_phone} {row.phone}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {row.has_address === 'true'
                              ? t('common-yes')
                              : t('common-no')}
                          </span>
                        </TableCell>
                        {role === 'retailerGM' && (
                          <TableCell align="left"><span className="text">{row.retailer_info.name} ({row.retailer_info.city})</span></TableCell>
                          )}
                        <TableCell align="left"><span className="text">{row.nomination_date}</span></TableCell>
                        <TableCell align="right">
                          <Link
                            to={`/retailer/hac-profile/${row.id}`}
                            className="profile"
                          >
                            <span className="icon-chevron-right"></span>
                            <span className="link">{t('common-profile')}</span>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div className="no-result">
                <h2 className="txt-center">
                  <small>{t('admin-main-tab1-text-empty')}</small>
                </h2>
              </div>
            )}
          </div>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('retailer-main-nominated-empty-title')}
              <small>{t('retailer-main-nominated-empty-text')}</small>
            </h2>
            <Link to="/retailer/nominate-hac" className="btn fit">
              + {t('retailer-main-nominated-action')}
            </Link>
          </div>
        )
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHacNominated;
