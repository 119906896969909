import './main.scss';
import React, { useState, useRef } from 'react';
import Header from '../../../organisms/header/header.js';
import FileUploader from '../../../organisms/role-super-admin/upload-file/upload-file.js';
import { Navigate } from 'react-router-dom';
import decodeToken from '../../../../utils/decode-token';
import ModalConfirmation from '../../../organisms/role-super-admin/modal/modal-confirmation.js';
import Input from '../../../atoms/input.js';
import { useForm } from 'react-hook-form';
import Joi from 'joi';

import {
  handleFileUpload,
  handleDownload,
  handleLoad,
  handleReset,
  handleClose,
  handleDelete,
} from './handlers';

const MainSuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [selectedOption, setSelectedOption] = useState('option1');
  const formRef = useRef();

  const [open, setOpen] = useState(false);
  const [loadMsg, setLoadMsg] = useState('');
  const [hasErrors, setHasErrors] = useState(false); 
  const [modalAction, setModalAction] = useState(() => () => {});
  const [modalText, setModalText] = useState('');

  const {
    formState: deleteFormState,
    register: registerDelete,
    trigger: triggerDelete,
    reset: resetDeleteForm,
  } = useForm({
    mode: 'onBlur',
  });
  const { errors: deleteErrors } = deleteFormState;

  const {
    formState: exportFormState,
    register: registerExport,
    reset: resetExportForm,
  } = useForm({
    mode: 'onBlur',
  });

  const { errors: exportErrors } = exportFormState;

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const handleMenuClick = (option) => {
    setSelectedOption(option);
    setLoadMsg('');
    setMsg('');
    setLoading(false);
    resetDeleteForm();
    resetExportForm();
  };

  const handleDeleteClick = async () => {
    const isValid = await triggerDelete();
    if (isValid) {
      setModalAction(() => handleDelete);
      setModalText('You will delete this user from the system:');
      setOpen(true);
    }
  };

  const handleExportClick = async () => {
    const isValid = await triggerDelete();
    if (isValid) {
      setModalAction(() => handleDelete);
      setModalText('You will delete this user from the system:');
      setOpen(true);
    }
  };

  return (
    <>
      {token && role === 'superAdmin' ? (
        <div className="App">
          <Header />
          <main>
            <section className="section-wrapper-super-admin">
              <div className="inner menu">
                <aside className="lateral-menu">
                  <h2>Menu</h2>
                  <ul>
                    <li onClick={() => handleMenuClick('option1')}>
                      Load data
                    </li>
                    <li onClick={() => handleMenuClick('option2')}>
                      Delete user
                    </li>
                    <li onClick={() => handleMenuClick('option3')}>
                      Export data to SV
                    </li>
                  </ul>
                </aside>
                <main className="preferences">
                  <div className="section-wrapper-super-admin">
                    {selectedOption === 'option1' ? (
                      <>
                        <div
                          className={`content-wrapper ${
                            fileUploaded ? 'inactive' : ''
                          }`}
                        >
                          <div className="step-circle">1</div>
                          <h3 className="additional-margin-top">
                            Download template for load data
                          </h3>
                          <button onClick={handleDownload}>Download</button>
                        </div>
                        <div className="ellipsis">...</div>
                        <div
                          className={`content-wrapper ${
                            fileUploaded ? 'inactive' : ''
                          }`}
                        >
                          <div className="step-circle">2</div>
                          <h3 className="additional-margin-top">Load file</h3>
                          <FileUploader
                            key={fileInputKey}
                            onFileUpload={(file) =>
                              handleFileUpload(
                                file,
                                setLoading,
                                setMsg,
                                setFileUploaded,
                                setLoadMsg
                              )
                            }
                            loading={loading}
                          />
                          <span className="file-name">
                            {msg && <p>{msg}</p>}
                          </span>
                        </div>
                        {fileUploaded && (
                          <>
                            <div className="ellipsis">...</div>
                            <div className="new-step">
                              <div className="step-circle step-3-circle">3</div>
                              <h3 className="additional-margin-top">
                                Process Completed
                              </h3>
                              <p>Your file has been uploaded.</p>
                              <button
                                className="reset-button"
                                onClick={() =>
                                  handleReset(
                                    setFileUploaded,
                                    setMsg,
                                    setFileInputKey,
                                    setFileUploaded,
                                    setLoadMsg,
                                    setHasErrors // Pasar el nuevo estado
                                  )
                                }
                              >
                                Restart
                              </button>
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  handleLoad('/pma', setLoadMsg, setHasErrors)
                                }
                              >
                                Load Pmas
                              </button>
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  handleLoad(
                                    '/retailer',
                                    setLoadMsg,
                                    setHasErrors
                                  )
                                }
                              >
                                Load Retailer
                              </button>
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  handleLoad(
                                    '/sales-person',
                                    setLoadMsg,
                                    setHasErrors
                                  )
                                }
                              >
                                Load SalePersons
                              </button>
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  handleLoad(
                                    '/retailer-general-manager',
                                    setLoadMsg,
                                    setHasErrors
                                  )
                                }
                              >
                                Load Retailer GM
                              </button>
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  handleLoad('/admin', setLoadMsg, setHasErrors)
                                }
                              >
                                Load Admins
                              </button>
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  handleLoad(
                                    '/vehicles-models',
                                    setLoadMsg,
                                    setHasErrors
                                  )
                                }
                              >
                                Load Vehicles
                              </button>
                              {loadMsg && (
                                <div
                                  className={`message ${
                                    hasErrors ? 'error' : 'success'
                                  }`}
                                >
                                  {loadMsg}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : selectedOption === 'option2' ? (
                      <div>
                        <form ref={formRef}>
                          <h2>Delete User</h2>
                          <div className="form-control required">
                            <Input
                              maxLength="50"
                              type="text"
                              id="delete-email"
                              label="E-mail address"
                              error={deleteErrors?.value}
                              {...registerDelete('value', {
                                validate: (v) => {
                                  const { error } = Joi.string()
                                    .email({ tlds: { allow: false } })
                                    .required()
                                    .error(
                                      () =>
                                        new Error('Email value is not valid')
                                    )
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />

                            <button
                              className="btn"
                              type="button"
                              onClick={handleDeleteClick}
                            >
                              Delete
                            </button>
                          </div>
                        </form>
                        {open ? (
                          <ModalConfirmation
                            open={open}
                            handleClose={() => handleClose(setOpen)}
                            formRef={formRef}
                            text={modalText}
                            action={modalAction}
                            setLoadMsg={setLoadMsg}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        <form ref={formRef}>
                          <h2>Export Data to SV</h2>
                          <div className="form-control required">
                            <Input
                              maxLength="40"
                              type="text"
                              id="export-sv"
                              label="HacId"
                              error={exportErrors?.value}
                              {...registerExport('value', {
                                validate: (v) => {
                                  const { error } = Joi.string()
                                    .required()
                                    .error(
                                      () =>
                                        new Error('HacId value is not valid')
                                    )
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />

                            <button
                              className="btn"
                              type="button"
                              onClick={handleExportClick}
                            >
                              Export
                            </button>
                          </div>
                        </form>
                        {loadMsg && <div className="message">{loadMsg}</div>}
                        {open ? (
                          <ModalConfirmation
                            open={open}
                            handleClose={() => handleClose(setOpen)}
                            formRef={formRef}
                            text={modalText}
                            action={modalAction}
                            setLoadMsg={setLoadMsg}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                </main>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default MainSuperAdmin;
