import React from 'react';
import './footer.scss';
import { useTranslation } from 'react-i18next';

function Footer() {

  const { t } = useTranslation();

  return (
    <footer>
      <section>
        <div className="inner">
          <ul>
            <li><a href="/hac/legal/print">{t('legal-print-title')}</a></li>
            <li><a href="/hac/legal/privacy">{t('legal-privacy-title')}</a></li>
            <li><a href="/hac/legal/terms">{t('legal-terms-title')}</a></li>
          </ul>
          <p>{t('legal-footer')}</p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
