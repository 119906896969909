import './expired-invitation.scss';
import Header from '../../../organisms/header/header.js'
import {useTranslation} from "react-i18next";

function ExpiredInvitation() {

  const { t } = useTranslation();

  return (
    <>
      <div className="App">
        <Header/>
        <main>
          <section className="error">
            <div className="inner">
              <strong className="code">{t('error-generic-code')}</strong>
              <h2>{t('error-expired-title')}</h2>
              <p>{t('error-expired-text')}</p>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default ExpiredInvitation;