import './profile.scss';
import Header from '../../../organisms/header/header.js'
import React, { useEffect, useState } from 'react';
import {Link, Navigate, useLocation} from "react-router-dom";
import HacVehicles from "../../../organisms/role-hac/hac-vehicles/hac-vehicles";
import Wadsworth from "../../../organisms/role-hac/wadsworth/wadsworth";
import {useTranslation} from "react-i18next";
import decodeToken from "../../../../utils/decode-token";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Vehicles() {

  const {t} = useTranslation();

  const token = sessionStorage.getItem('token') || '';
  const role = decodeToken(token).role;

  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);


  useEffect(() => {
    if (location.state?.openSnackbar) {
      setOpenSnackbar(true);
    }
  }, [location.state]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {
        (token && role === 'hac') ? (
          <div className="App">
            <Header/>
            <main>
              <Wadsworth/>
              <section className="profile">
                <div className="inner menu">
                  <aside className="lateral-menu">
                    <h2>{t('hac-menu-profile-title')}</h2>
                    <ul>
                      <li><Link to="/hac/profile/basic-info">{t('hac-menu-profile-menu1')}</Link></li>
                      <li className="on"><Link to="/hac/profile/vehicles">{t('hac-menu-profile-menu2')}</Link></li>
                    </ul>
                  </aside>
                  <main>
                    <h4>{t('hac-my-profile-vehicles-title')}</h4>
                    <p>{t('hac-my-profile-vehicles-text')}</p>
                    <HacVehicles/>
                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                    >
                      <Alert onClose={handleSnackbarClose} severity="success" sx={{width: '100%'}}>
                        Vehicle saved successfully!
                      </Alert>
                    </Snackbar>
                  </main>
                </div>
              </section>
            </main>
          </div>

        ) : (
          <Navigate to="/login"/>
        )
      }
    </>

  )
}

export default Vehicles;