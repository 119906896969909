import './main.scss';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import TableHacNominated from '../../../organisms/role-retailer/table-hac-nominated/table-hac-nominated';
import TableHacActive from '../../../organisms/role-retailer/table-hac-active/table-hac-active';
import TableHacExited from "../../../organisms/role-retailer/table-hac-exited/table-hac-exited";
import {useTranslation} from "react-i18next";

function MainRetailer() {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('nominated');
  const [reloadNominated, setReloadNominated] = useState(true);
  const [reloadActive, setReloadActive] = useState(true);
  const [reloadExited, setReloadExited] = useState(true);

  useEffect(() => {
    // Check if the URL pathname contains '/active' and set the activeTab accordingly
    if (location.pathname.includes('/active')) {
      setActiveTab('active');
    }
    if (location.pathname.includes('/exited')) {
      setActiveTab('exited');
    }
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'nominated') {
      setReloadNominated(true);
      navigate('/retailer/main/nominated');
    } else if (tab === 'active') {
      setReloadActive(true);
      navigate('/retailer/main/active');
    } else if (tab === 'exited') {
      setReloadExited(true);
      navigate('/retailer/main/exited');
    }
  };

  return (
    <>
      {
        (token && (role === 'retailer' || role === 'retailerGM')) ? (
        <div className="App">
          <Header />
          <main>
            <div className="tabs">
              <ul>
                <li className={activeTab === 'nominated' ? 'active' : ''}>
                  <span onClick={() => handleTabClick('nominated')}>{t('retailer-main-tabs-tab1')}</span>
                </li>
                <li className={activeTab === 'active' ? 'active' : ''}>
                  <span onClick={() => handleTabClick('active')}>{t('retailer-main-tabs-tab2')}</span>
                </li>
                <li className={activeTab === 'exited' ? 'active' : ''}>
                  <span onClick={() => handleTabClick('exited')}>{t('common-exited')}</span>
                </li>
              </ul>
            </div>

            {activeTab === 'nominated' && (
              <section className={`tab-content ${activeTab === 'nominated' ? 'active' : ''}`}>
                <div className="inner">
                  <TableHacNominated reload={reloadNominated} onDataLoaded={() => setReloadNominated(false)} />
                </div>
              </section>
            )}
            {activeTab === 'active' && (
              <section className={`tab-content ${activeTab === 'active' ? 'active' : ''}`}>
                <div className="inner">
                  <TableHacActive reload={reloadActive} onDataLoaded={() => setReloadActive(false)} />
                </div>
              </section>
            )}
            {activeTab === 'exited' && (
              <section className={`tab-content ${activeTab === 'exited' ? 'active' : ''}`}>
                <div className="inner">
                  <TableHacExited reload={reloadExited} onDataLoaded={() => setReloadExited(false)} />
                </div>
              </section>
            )}
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default MainRetailer;
