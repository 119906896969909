import './modal-hac-edit-preferences.scss';
import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Input from '../../../atoms/input.js';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { Alert } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalHacEditPreferences({
  open,
  handleClose,
  selectedPreference,
  cat,
  onSave,
}) {
  const { t } = useTranslation();

  const [id, setId] = useState('');
  const [isNewPreference, setIsNewPreference] = useState(true);
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const token = sessionStorage.getItem('token') || null;
  const { register, handleSubmit, setValue, formState } = useForm();

  const { errors } = formState;

  const resetForm = useCallback(() => {
    setValue('title', '');
    setValue('value', '');
    setId('');
    setIsNewPreference(true);
  }, [setValue, setId, setIsNewPreference]);

  useEffect(() => {
    if (selectedPreference) {
      setValue('title', selectedPreference.title);
      setValue('value', selectedPreference.value);
      setId(selectedPreference.id || '');
      setIsNewPreference(!selectedPreference.id);
      //console.log(!selectedPreference.id)
    } else {
      resetForm();
    }
  }, [selectedPreference, setValue, resetForm, setId, setIsNewPreference]);

  const onSubmit = handleSubmit(async (data, event) => {
    event.preventDefault();

    const payload = { ...data, category_id: cat };
    if (id) {
      payload.id = id;
    }

    if (payload.value === '') {
      delete payload.value;
    }

    const url = `${process.env.REACT_APP_API_URL}/hac/preference`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    };

    axios
      .post(url, payload, config)
      .then((response) => {
        handleClose();
        onSave(); // Llama al callback onSave después de guardar
        setMessage(t('hac-preferences-edit-feedback'));
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error('Error al guardar los datos:', error);
        setMessage(t('common-internal-server-error'));
        setOpenSnackbar(true);
      });
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="edit-preferences{">
        <Box sx={style}>
          <button
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <form onSubmit={onSubmit}>
            <h2>
              {isNewPreference
                ? t('hac-preferences-edit-title-new')
                : t('hac-preferences-edit-title-edit')}
            </h2>
            <p className="txt-left">
              <small>{t('common-category')}</small> <br/>
              <strong>{t(`hac-preferences-${cat}`)}</strong>
            </p>
            <div className="form-control required">
              <Input
                type="text"
                id="title"
                label={t('common-title')}
                error={errors?.title}
                {...register('title', {
                  validate: (v) => {
                    const {error} = Joi.string()
                      .max(255)
                      .required()
                      .error(() => new Error(t('hac-preferences-edit-error')))
                      .validate(v);
                    return error?.message ? error.message : true;
                  },
                })}
              />
            </div>
            <div className="form-control">
              <label htmlFor="value">{t('common-description')}</label>
              <textarea
                id="value"
                {...register('value')}
                className="txt-area"
              />
            </div>
            <div className="actions">
              <button
                className="btn secondary"
                onClick={handleClose}
                type="button"
              >
                {t('common-cancel')}
              </button>
              <button className="btn" type="submit">
                {t('common-save')}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalHacEditPreferences;
