import './table-retailer.scss';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import decodeToken from '../../../../utils/decode-token';
import { Alert, Snackbar } from '@mui/material';

function TableRetailer({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const [dataLoaded, setDataLoaded] = useState(false);

  const [retailers, setRetailers] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [markets] = useState([
    { name: 'au-cz-sk', countries: ['AT', 'CZ', 'SK'] },
    { name: 'benelux', countries: ['BE', 'LUX', 'NE'] },
    { name: 'france', countries: ['FR'] },
    { name: 'germany', countries: ['DE'] },
    { name: 'iberia', countries: ['ES', 'PT'] },
    { name: 'italy', countries: ['IT'] },
    { name: 'switzerland', countries: ['CH'] },
    { name: 'turkey', countries: ['TR'] },
  ]);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
    city: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/admin/retailers`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.get(url, config);
      if (response.status === 200) {
        const data = response.data;
        const storedFilters = JSON.parse(sessionStorage.getItem('admin-retailer-filters') || '{}');
        const initialFilters = {
          ...filters,
          ...storedFilters,
        };
        setFilters(initialFilters);
        setRetailers(data);
        applyFilters(initialFilters, data);
        setDataLoaded(true);
        onDataLoaded();

        const market = markets.find((m) => m.name === initialFilters.market);
        const availableCountries = market ? market.countries : [...new Set(data.map((retailer) => retailer.area_code))];
        const uniqueCities = [...new Set(data.map((retailer) => retailer.city))];
        setCountries(availableCountries);
        setCities(uniqueCities);
      }
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    }
  };

  useEffect(() => {
    if (reload) {
      fetchData();
    }
  }, [reload, token, onDataLoaded, t]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);
      const updatedCountries = selectedMarket ? selectedMarket.countries : [...new Set(retailers.map((retailer) => retailer.area_code))];
      setCountries(updatedCountries);

      setFilters({
        ...filters,
        market: value,
        country: '',
        city: '',
      });
      setCities([...new Set(retailers.map((retailer) => retailer.city))]); // Reset cities to all available cities.
    } else if (name === 'country') {
      const filteredCities = value
        ? retailers
          .filter((retailer) => retailer.area_code === value)
          .map((retailer) => retailer.city)
        : retailers.map((retailer) => retailer.city); // Show all cities if no specific country is selected.

      const uniqueCities = [...new Set(filteredCities)];
      setCities(uniqueCities);

      setFilters({
        ...filters,
        country: value,
        city: '', // Reset city filter when changing country.
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = (appliedFilters = filters, retailersData = retailers) => {
    let filtered = [...retailersData];
    if (appliedFilters.name) {
      filtered = filtered.filter((retailer) =>
        retailer.name.toLowerCase().includes(appliedFilters.name.toLowerCase())
      );
    }
    if (appliedFilters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === appliedFilters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((retailer) =>
          selectedMarket.countries.includes(retailer.area_code)
        );
      }
    }
    if (appliedFilters.country) {
      filtered = filtered.filter(
        (retailer) => retailer.area_code === appliedFilters.country
      );
    }
    if (appliedFilters.city) {
      filtered = filtered.filter((retailer) =>
        retailer.city.toLowerCase().includes(appliedFilters.city.toLowerCase())
      );
    }
    setFilteredRetailers(filtered);
    sessionStorage.setItem('admin-retailer-filters', JSON.stringify(appliedFilters));
  };

  const handleApplyFilter = () => {
    applyFilters();
  };

  return (
    <>
      {dataLoaded ? (
        retailers && retailers.length !== 0 ? (
          <>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={3}>
                  <div className="form-control">
                    <label htmlFor="name">{t('common-name')}</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                {role === 'admin' && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets.map((market) => (
                          <option key={market.name} value={market.name}>
                            {t(`market-${market.name}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                {(role === 'admin' ||
                  role === 'marketAdmin' ||
                  role === 'countryAdmin') && (
                  <Grid xs={12} sm={6} md={2}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {t(`countries-${country}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="city">{t('common-city')}</label>
                    <select
                      name="city"
                      id="city"
                      className="normal"
                      value={filters.city}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-city')}</option>
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <div className="actions">
                    <button className="btn" onClick={handleApplyFilter}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="summary">
              <strong>
                {t('common-total-results')}:{' '}
                <span>{filteredRetailers.length}</span>
              </strong>
            </div>
            <div>
              {filteredRetailers.length !== 0 ? (
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="user-table admin-retailer"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"><span className="text">{t('common-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-country')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-city')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-number-sale-people')}</span></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRetailers.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left"><span className="text">{row.name}</span></TableCell>
                        <TableCell align="left"><span className="text">{t(`countries-${row.area_code}`)}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.city}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.num_salespersons}</span></TableCell>
                        <TableCell align="right">
                          <Link
                            to={`/admin/retailer-info/${row.id}`}
                            className="profile"
                          >
                            <span className="icon-chevron-right"></span>
                            <span className="link">
                              {t('common-retailer-details')}
                            </span>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="no-result">
                  <h4 className="txt-center">{t('admin-main-title-empty')}</h4>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('admin-main-tab3-title-empty')}
              <small>{t('admin-retailer-tab3-text-empty')}</small>
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableRetailer;
