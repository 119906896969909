import './preferences.scss';
import React from 'react';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import { useTranslation } from 'react-i18next';
import RetailerPreferencesHac from "../../../organisms/role-retailer/hac-preferences/preferences-hac";


const HacListRetailerPreferences = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const navigate = useNavigate();

  return (
    <>
      {
        (token && (role === 'retailer' || role === 'retailerGM')) ? (
        <div className="App">
          <Header />
          <main>
            <section>
              <div className="inner">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="back-button"
                >
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                <h4>{t('pma-preferences-title')}</h4>
                <main className="preferences">
                  <RetailerPreferencesHac />
                </main>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default HacListRetailerPreferences;
