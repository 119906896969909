import './error404.scss';
import Header from '../../../organisms/header/header.js'
import {useTranslation} from "react-i18next";

function Error404() {

  const { t } = useTranslation();

  return (
    <>
      <div className="App">
        <Header/>
        <main>
          <section className="error">
            <div className="inner">
              <strong className="code">{t('error-error404-code')}</strong>
              <h2>{t('error-error404-title')}</h2>
              <p>{t('error-error404-text')}</p>
              <div className="actions">
                <a href="/" className="btn">{t('error-error404-action')}</a>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default Error404;