import './wadsworth.scss';
import React, { useEffect, useState } from 'react';
import ModalHacShowContact from '../modal-hac-show-contact/modal-hac-show-contact';
import { useTranslation } from 'react-i18next';
// import { jwtDecode } from 'jwt-decode';
import apiClient from '../../../../utils/apiClient';
import { Alert, Snackbar } from '@mui/material';

function Wadsworth() {
  const { t, i18n } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');

  const [, setIsModalExpiredSessionOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token') || null;

        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        // const decodedToken = jwtDecode(token);
        // const userId = decodedToken.id;

        const url = `${process.env.REACT_APP_API_URL}/hac/basic-info`;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await apiClient.get(url, config);
        setTitle(response.data.title);
        setName(response.data.name);
        setLastName(response.data.lastName);
        const userLanguage = response.data.preferredLanguage;
        i18n.changeLanguage(userLanguage.toLowerCase());
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }

        setIsModalExpiredSessionOpen(true);
      }
    };

    fetchData();
  }, [i18n]);

  const showContact = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /*const handleCloseModalExpiredSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refresh_token');
    setIsModalExpiredSessionOpen(false);
    navigate('/login');
  };*/

  return (
    <>
      <div className="wadsworth">
        <div className="inner">
          <p>
            {t('common-greetings')}<br />
            {t(`common-title-${title}`)} {name} {lastName}
          </p>
          <button className="btn" onClick={showContact}>
            <span className="text">{t('common-contact-assistant')}</span>
            <span className="icon-phone"></span>
          </button>
        </div>
      </div>
      <ModalHacShowContact
        open={openModal}
        handleClose={handleCloseModal}
        className="modal-contact"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Wadsworth;
